import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colorsGlobal } from "./_global";
import ResizeObserver from "resize-observer-polyfill";

const IFrame = ({ src, frameBorder = 0, allow, allowFullScreen = true }) => {
  const [isLoading, setLoading] = useState(true);
  const wrapperRef = useRef(null);
  const [wrapperHeight, setWrapperHeight] = useState(0);

  const handleLoadedIFrame = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (wrapperRef && wrapperRef.current) {
      const tyreLabelObserver = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          setWrapperHeight((entry.contentRect.width * 9) / 16);
        });
      });
      tyreLabelObserver.observe(wrapperRef.current);
      return () => tyreLabelObserver.disconnect();
    }
    return undefined;
  }, [wrapperRef]);

  return (
    <div
      ref={wrapperRef}
      style={{
        display: "flex",
        flexShrink: "0",
        position: "relative",
        width: "100%",
        height: `${wrapperHeight}px`,
        background: "rgba(204, 166, 255, 0.1)",
      }}
    >
      {isLoading && <Spinner />}
      <IFrameStyled
        src={src}
        onLoad={handleLoadedIFrame}
        frameBorder={frameBorder}
        allow={allow}
        allowFullScreen={allowFullScreen}
      />
    </div>
  );
};

const IFrameStyled = styled.iframe`
  height: 100%;
  width: 100%;
`;

const Spinner = styled.div`
  @keyframes rotate-spinner {
    100% {
      transform: rotate(360deg);
    }
  }
  border: 4px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  background: ${colorsGlobal.primary};
  border-top-color: white;
  opacity: 1;
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  width: 16px;
  height: 16px;
  transition: opacity 250ms;
  animation: rotate-spinner 1s linear;
  animation-iteration-count: infinite;
`;

export default IFrame;
