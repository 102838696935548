import React from "react";
import styled from "styled-components";
import {
  socialButtonsColors,
  socialButtonsColorsHover,
  socialButtonsColorsLight,
} from "../_global";
import { mediaQuery } from "../_breakpoints";

const SocialButton = ({
  name,
  backgroundColor,
  link,
  className,
  id,
  isActive,
  disabled,
  activeColor,
  smallButton,
  handleClick,
  ...rest
}) => {
  return (
    <StyledButton
      {...rest}
      className={className}
      isActive={isActive}
      disabled={disabled}
      onClick={() => handleClick(id)}
      activeColor={
        activeColor || socialButtonsColors[name] || socialButtonsColors.default
      }
      backgroundLight={
        backgroundColor ||
        socialButtonsColorsLight[name] ||
        socialButtonsColorsLight.default
      }
      background={socialButtonsColors[name] || socialButtonsColors.default}
    >
      {name}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  display: inline-grid;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  white-space: nowrap;
  outline: none;
  background: ${(props) => {
    if (props.disabled) {
      return "#e2e2e2";
    }
    if (props.isActive) {
      if (props.activeColor) {
        return props.activeColor;
      }
      return socialButtonsColors.default;
    }
    return props.backgroundLight;
  }};
  border: 2px solid
    ${(props) => (!props.disabled ? props.background : "#a9a9a9")};
  text-transform: uppercase;
  text-decoration: none;
  font-family: "Lato", sans-serif;
  color: black;
  font-weight: 700;
  line-height: 16px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin: 3px;
  transition: background 0.1s ease;
  &:hover {
    background: ${(props) => {
      if (props.disabled) {
        return "#e2e2e2";
      }
      if (props.hoverColor) {
        return props.hoverColor;
      }
      return socialButtonsColorsHover.default;
    }};
  }

  ${mediaQuery.sm(`
  font-size: 14px;
    height: auto;
    line-height: 13px;
    padding: 8.5px 20px;
    width: auto;
    margin: 3px;
  `)}
`;

export default SocialButton;
